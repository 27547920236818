const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.riotinto.forwoodsafety.com',
    USER_TOKEN_URL: 'https://wzmc3sfhh4.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ezo4zavcu8.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.0',
    HOSTNAME: 'https://admin.testing.riotinto.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.testing.riotinto.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.testing.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0y3pdsom96.execute-api.us-east-1.amazonaws.com/testing'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;